<template>
  <div>
    <v-sheet>
      <!-- Luego de confirmar la órden de distribución, se actualizarán los datos que usted haya modificado del cliente: -->
      <v-alert
        border="left"
        colored-border
        color="warning"
        icon="mdi-map-marker-plus"
        elevation="2"
      >
        Este formulario se utiliza para registrar un Cliente Nuevo
        <v-btn
          color="primary"
          @click="onSubmit"
          :loading="loading"
        >
          <v-icon left>mdi-keyboard-f4</v-icon>
          Registrar
          <v-icon right>mdi-check</v-icon>
        </v-btn>
      </v-alert>
      <v-alert
        v-if="clienteDuplicado"
        border="left"
        colored-border
        color="error"
        icon="mdi-cancel"
        elevation="2"
      >
        Ya existe un cliente con el mismo Apellido y nombre
      </v-alert>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-row no-gutters>
          <v-col cols="12">
            <v-text-field
              v-model="cliente"
              placeholder="Ingrese apellido y nombre del cliente"
              prepend-icon="mdi-tag"
              :rules="rules.cliente"
              label="Apellido y nombre"
              @blur="onBlurCliente"
              :loading="loadingClientes"
              v-bind="attrs"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="domicilio"
              placeholder="Domicilio"
              :rules="rules.descripcion"
              prepend-icon="mdi-map-marker"
              label="Domicilio"
              v-bind="attrs"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="observaciones"
              placeholder="Observaciones"
              prepend-icon="mdi-text"
              :rules="rules.observaciones"
              label="Observaciones del domicilio"
              v-bind="attrs"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <my-zona-select
              v-model="zona"
              :rules="rules.zona_id"
            ></my-zona-select>
          </v-col>
        </v-row>
      </v-form>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import ZonaSelect from '@/pages/zonas/Select'
export default {
  components: {
    'my-zona-select': ZonaSelect
  },
  model: {
    prop: 'selected',
    event: 'change'
  },
  data () {
    return {
      attrs: {
        color: 'primary',
        readonly: false,
      },
      valid: true
    }
  },
  props: {
    reset: {
      type: Boolean,
      default: false
    }
  },
  beforeMount() {
    // Agrega el event listener en el gancho beforeMount
    window.addEventListener('keydown', this.handleKeyboard);
  },
  beforeDestroy() {
    // Remueve el event listener en el gancho beforeDestroy
    window.removeEventListener('keydown', this.handleKeyboard);
  },
  created () {
    // this.onDefaults()
    // if (this.$route.params.id)
    //   this.cliente = this.$route.params.id
  },
  computed: {
    ...mapGetters('DomiciliosFindOrCreate', ['edited', 'rules', 'loading', 'filters', 'errors', 'loadingClientes']),
    ...mapGetters('Ordenes', { editedOrden: 'edited' }),
    id () {
      return this.edited.id
    },
    clienteDuplicado: {
      get () {
        return this.errors.duplicado
      },
      set (value) {
        this.setErrors({
          ...this.errors,
          duplicado: value
        })
      }
    },
    cliente: {
      get () {
        return this.edited.cliente
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          cliente: value
        })
      }
    },
    domicilio: {
      get () {
        return this.edited.descripcion
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          descripcion: value
        })
      }
    },
    observaciones: {
      get () {
        return this.edited.observaciones
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          observaciones: value
        })
      }
    },
    zona: {
      get () {
        return this.edited.zona_id
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          zona_id: value
        })
      }
    },
    searchText: {
      get () {
        return this.filters.searchText
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          searchText: value
        })
      }
    },
  },
  watch: {
    resetValidation (value) {
      if (value) this.$refs.form.resetValidation()
    }
  },
  methods: {
    ...mapActions('DomiciliosFindOrCreate', ['onSave', 'onFindByCliente']),
    ...mapMutations('DomiciliosFindOrCreate', ['setFilters', 'setEdited', 'setSelected']),
    ...mapMutations('DomiciliosFindOrCreate', ['setErrors']),
    ...mapMutations('Ordenes', { setEditedOrden: 'setEdited' }),
    onSubmit (event) {
      this.$refs.form.validate()
      if (this.valid) {
        this.onSave().then((data) => {
          this.setEditedOrden({
            ...this.editedOrden,
            domicilio_id: this.edited.id
          })
          this.$emit('change', this.edited.id)
        })
      }
      event.preventDefault()
    },
    handleKeyboard(event) {
      if (!this.loading) {
        if (event.key === 'F4') {
          if (!this.clienteDuplicado) this.onSubmit(event)
          else alert('Ya existe un cliente con el mismo Apellido y nombre')
        }
      }
    },
    onBlurCliente (event) {
      if (this.cliente && this.cliente.length > 0) this.onFindByCliente()
      else if (this.cliente === '' || this.cliente === null || this.cliente === undefined) this.setErrors({
        ...this.errors,
        duplicado: false
      })
      event.preventDefault()
    }
  },
}
</script>
