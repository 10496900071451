const URL = '/domicilios';

const state = {
  all: [],
  edited: {
    id: null,
    cliente: null,
    descripcion: null,
    observaciones: null,
    cliente_id: null,
    zona_id: null,
  },
  selected: {
    id: null,
    cliente: null,
    descripcion: null,
    observaciones: null,
    cliente_id: null,
    zona_id: null,
  },
  filters: {
    searchText: null,
    cliente_id: null,
    inactivos: null
  },
  loading: false,
  loadingClientes: false,
  headers: [
    {
      text: 'Editar',
      value: 'cliente_id',
      align: 'center'
    },
    {
      text: 'Cliente',
      value: 'cliente.razon_social',
      align: 'center'
    },
    {
      text: 'Domicilio',
      value: 'descripcion',
      align: 'center'
    },
    {
      text: 'Observaciones',
      value: 'observaciones',
      align: 'center'
    },
    {
      text: 'Zona',
      value: 'zona.nombre',
      align: 'center'
    },
  ],
  errors: {
    duplicado: null,
  },
  rules: {
    cliente: [
      v => !!v || 'Este campo es necesario',
    ],
    descripcion: [
      v => !!v || 'Este campo es necesario',
    ],
    latitud: [
      // v => !!v || 'Este campo es necesario',
    ],
    longitud: [
      // v => !!v || 'Este campo es necesario',
    ],
    observaciones: [
      // v => !!v || 'Este campo es necesario',
    ],
    cliente_id: [
      v => !!v || 'Este campo es necesario',
      v => _.isInteger(v) && v > 0 || 'No ha seleccionado un cliente',
    ],
    zona_id: [
      v => !!v || 'Este campo es necesario',
      v => _.isInteger(v) && v > 0 || 'No ha seleccionado un zona',
    ],
  },
  default: {
    id: null,
    cliente: null,
    descripcion: null,
    latitud: null,
    longitud: null,
    observaciones: null,
    cliente_id: null,
    zona_id: null,
  },
  options: {},
  itemsLength: null,
}

// GETTERS
const getters = {
  all (state) {
    return state.all
  },
  filters (state) {
    return state.filters
  },
  itemsLength (state) {
    return state.itemsLength
  },
  options (state) {
    return state.options
  },
  selected (state) {
    return state.selected.cliente ? state.selected : null
  },
  errors (state) {
    return state.errors
  },
  isSelected (state) {
    return state.edited.id ? true : false
  },
  editedAndSelectedEquals (state) {
    return state.edited.id == state.selected.id &&
            state.edited.cliente == state.selected.cliente &&
            state.edited.descripcion == state.selected.descripcion &&
            state.edited.observaciones == state.selected.observaciones &&
            state.edited.zona_id == state.selected.zona_id
  },
  domicilioNuevo (state) {
    return state.edited.id === null || state.edited.id === undefined
  },
  edited (state) {
    return state.edited
  },
  headers (state) {
    return state.headers
  },
  rules (state) {
    return state.rules
  },
  loading (state) {
    return state.loading
  },
  loadingClientes (state) {
    return state.loadingClientes
  }
}

// ACTIONS
const actions = {
  onClearSelected ({ commit }) {
    commit('setSelected', state.default)
  },
  onClearEdited ({ commit }) {
    commit('setEdited', state.default)
  },
  onFetch ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.get(URL, {
          params: {
            itemsPerPage: state.options.itemsPerPage,
            page: state.options.page,
            searchText: state.filters.searchText,
            cliente_id: state.filters.cliente_id,
            inactivos: state.filters.inactivos
          }
        })
        .then(response => {
          commit('setAll', response.data.data)
          commit('setItemsLength', response.data.total)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onFindByCliente ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoadingClientes', true)
      window.axios.get('clientes/duplicado/' + state.edited.cliente, {
        params: {
          razon_social: state.edited.cliente,
          cliente_id: state.edited.id
        }
      })
        .then(response => {
          commit('setErrors', {
            ...state.errors,
            duplicado: response.data.duplicado
          })
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoadingClientes', false)
        })
    })
  },
  onFind ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.get(URL + '/' + payload)
        .then(response => {
          commit('setEdited', response.data)
          commit('setSelected', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onPredeterminado ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.get(URL + '/cliente/' + state.cliente + '/predeterminado')
        .then(response => {
          commit('setSelected', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onSave ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.post(URL, state.edited)
        .then(response => {
          const aux = Object.assign(state.edited, {
            cliente_id: response.data.data.cliente_id,
            id: response.data.data.id
          })
          commit('setSelected', aux)
          commit('setEdited', aux)
          commit('ResponseHTTP/addSuccess', 'Se registro la informacion del cliente y domicilio', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error: no se registro la informacion del cliente y domicilio', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onUpdate ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.put(URL + '/' + state.edited.id, state.edited)
        .then(response => {
          commit('setSelected', state.edited)
          commit('ResponseHTTP/addSuccess', 'Se actualizo la informacion del cliente y domicilio', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error: no se actualizo la informacion del cliente y domicilio', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onDelete ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.delete(URL + '/' + state.edited.id)
        .then(response => {
          commit('ResponseHTTP/addSuccess', 'Se elimino la informacion del cliente y domicilio', { root: true })
          dispatch('onClearEdited')
          dispatch('onClearSelected')
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error: no se elimino la informacion del cliente y domicilio', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
}

// MUTATIONS
const mutations = {
  setAll (state, payload) {
    if (payload && payload.length > 0) state.all = payload.slice()
    else state.all = []
  },
  setFilters (state, payload) {
    state.filters = Object.assign({}, payload)
  },
  setSelected (state, payload) {
    state.selected = Object.assign({}, payload)
  },
  setEdited (state, payload) {
    state.edited = Object.assign({}, payload)
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setLoadingClientes (state, payload) {
    state.loadingClientes = payload
  },
  setErrors (state, payload) {
    state.errors = Object.assign({}, payload)
  },
  setPage (state, payload) {
    state.options.page = payload
  },
  setItemsPerPage (state, payload) {
    state.options.itemsPerPage = payload
  },
  setItemsLength (state, payload) {
    state.itemsLength = payload
  },
  setOptions (state, payload) {
    state.options = payload
  },
  setFiltersClienteId (state, payload) {
    state.filters.cliente_id = payload
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
